.Hero .Polygons {
  overflow: hidden;
  width: 100%;
  z-index: -1;
  height: 100%;
  position: absolute;
}

.Hero .Polygons svg {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -3;
}

.Hero .PolygonLeft {
  position: absolute;
  z-index: -2;
}

.Hero .PolygonRight {
  position: absolute;
  z-index: -2;
}

.Hero .HeroContent {
  padding-top: 7rem;
  padding-left: 6rem;
  padding-bottom: 4.2rem;
}

.Hero .HeroContent .Text {
  font-size: 3.03143rem;
  width: 41rem;
}

.Hero .Background {
  width: 100vw;
  height: 510px;
  position: absolute;
  z-index: -5;
}

@media (max-width: 1152px) {
  .Hero .PolygonInner {
    display: none;
  }
  .Hero .HeroContent .Text {
    font-size: 2.03143rem;
    width: 20rem;
  }
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .Hero .HeroContent {
    padding-left: 1.2rem;
    padding-bottom: 2.2rem;
    padding-top: 5rem;
  }

  .Hero .HeroContent .Text {
    font-size: 1.73143rem;
    width: 20rem;
  }
}
