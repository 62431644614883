.FixedNav {
  position: fixed;
  right: 0px;
  top: 10%;
  background-color: #202e4c;
  width: 60px;
  border-radius: 4px;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.FixedNav .Item {
  padding: 0.5em;
  border-bottom: 1px solid #484848;
  cursor: pointer;
}
.FixedNav .Item.Active {
  color: #f5d24b;
}
.FixedNav .Item:last-child {
  border: none;
}
