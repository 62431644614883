.Footer {
  background-color: #db0a41;
  color: #fff;
  padding-top: 1em;
  padding-left: 5em;
  padding-right: 5em;
  font-weight: 100;
  display: flex;
  flex-direction: column;
}

.Footer .Lists {
  display: flex;
  margin-bottom: 3em;
  margin-left: auto;
}
.Footer .Lists .List {
  margin-left: 100px;
  text-align: left;
  font-size: 0.7em;
  font-weight: 400;
  line-height: 1.7em;
}
.Footer img {
  height: 40px;
}
.Footer .Copyright {
  text-align: center;
  font-size: 0.7em;
  margin-bottom: 5px;
}

.Footer .Container {
  display: flex;
  align-items: center;
}

@media (max-width: 1100px) {
  .Footer .Lists .List {
    font-size: 0.5em;
    margin-left: 60px;
  }
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .Footer {
    padding-left: 0;
    padding-right: 0;
  }
  .Footer .Lists,
  .Footer .Container {
    flex-direction: column;
  }
  .Footer .Lists {
    margin-left: 0;
  }
  .Footer .Lists .List {
    font-size: 0.8em;
    text-align: center;
    margin-left: 0px;
  }
}

.AboutFooter {
  cursor: pointer;
}
