.Stage2 {
  width: 40%;
  border-radius: 8px;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5em;
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.Stage2 .Form input,
.Stage2 .Form textarea {
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  width: 20%;
  font-size: 1.5em;
  outline: none;
  padding: 0.3em;
  color: #db0a41;
  font-weight: 100;
  margin: 0.5em;
  width: 75%;
}

.Stage2 .Form .Item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Stage2 .Form .Item.Top {
  align-items: baseline;
}
.Stage2 .Form .Item label {
  width: 25%;
  text-align: right;
}

.Stage2 .Buttons {
  display: flex;
  justify-content: center;
}
.Stage2 .Buttons button {
  background-color: #26ddac;
  color: #000;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 0.5em;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  margin-right: 1em;
  margin-left: 1em;
  transition: background-color 0.4s, color 0.4s, transform 0.4s;
  border: 1px solid transparent;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
}
.Stage2 .Buttons button.Secondary {
  background-color: transparent;
  border: 1px solid #ed003d;
}
.Stage2 .Buttons button.Secondary:hover {
  transform: none;
  background-color: #ed003d;
  color: #fff;
}
.Stage2 .Buttons button:hover {
  transform: scale(1.1);
}
