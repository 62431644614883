.ContactContent {
  text-align: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3em;
  margin-top: 2em;
}

.ContactContent input,
.ContactContent textarea {
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  width: 20%;
  font-size: 1.5em;
  outline: none;
  padding: 0.3em;
  color: #db0a41;
  font-weight: 100;
  margin: 0.5em;
  width: 43%;
}

.ContactContent input.Error,
.ContactContent textarea.Error {
  border: 1px solid #db0a41;
}

.ContactContent .Form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}

.ContactContent .Form textarea.Message {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.ContactContent .Form button {
  background-color: #ed003d;
  color: #fff;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1em;
  padding: 0.5em 1em;
  -webkit-transition: background-color 0.4s, color 0.4s, -webkit-transform 0.4s;
  transition: background-color 0.4s, color 0.4s, -webkit-transform 0.4s;
  transition: background-color 0.4s, color 0.4s, transform 0.4s;
  transition: background-color 0.4s, color 0.4s, transform 0.4s,
    -webkit-transform 0.4s;
  border: 1px solid transparent;
  box-shadow: 0 5px 20px rgba(25, 17, 34, 0.1);
  margin: auto;
  width: 47%;
}

.ContactContent .Form button:hover {
  transform: scale(1.02);
}

.ContactContent .Response {
  font-size: 1.3em;
  font-weight: 100;
  padding: 5em;
}
@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .ContactContent input,
  .ContactContent textarea {
    width: 90%;
  }
  .ContactContent {
    width: 100%;
  }
}
.ContactContent .EmailAddress {
  color: #db0a41;
  font-size: 1.6em;
}
