.Page1 {
  position: relative;
}
.Page1 .Polygons {
  overflow: hidden;
  width: 100%;
  z-index: -1;
  height: 100%;
  position: absolute;
}

.Page1 .Polygons svg {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -3;
}

.Page1 .PolygonLeft {
  position: absolute;
  z-index: -2;
}

.Page1 .PolygonRight {
  position: absolute;
  z-index: -2;
}

.Page1 .HeroContent {
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 0;
}

.Page1 .HeroContent .Text {
  font-size: 5.03143rem;
  width: 55rem;
}

.Page1 .Background {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: -5;
}

.Page1 .Logos {
  display: flex;
  justify-content: center;
  width: 50%;
}
@media (max-width: 1152px) {
  .Page1 .PolygonInner {
    display: none;
  }
  .Page1 .HeroContent .Text {
    font-size: 2.03143rem;
    width: 20rem;
  }
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .Page1 .HeroContent {
    padding-left: 1rem;
    padding-bottom: 2.2rem;
  }

  .Page1 .HeroContent .Text {
    font-size: 1.73143rem;
    width: 20rem;
  }
}

.Page1 .Logo {
  height: 111px;
}
.Page1 .Scroll {
  position: absolute;
  bottom: 20px;
  color: #fff;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 2em;
  font-weight: 100;
}
.Page1 .Scroll svg {
  height: 30px;
  cursor: pointer;
}

.bounce {
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
