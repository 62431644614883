.SubContent {
  max-width: 33.3333%;
  box-shadow: rgb(245, 243, 247) 0px 1px 0px 0px;
  border-left: 0px solid rgb(245, 243, 247);
  box-sizing: border-box;
}

@media (min-width: 750px) {
  .SubContent:nth-child(2n) {
    border-left: 1px solid rgb(245, 243, 247);
  }

  .SubContent:nth-child(3n) {
    border-left: 1px solid #f5f3f7;
  }
  .SubContent:nth-child(5n) {
    border-left: 1px solid #f5f3f7;
  }
}

.SubContent .Inner {
  padding: 4.2rem;
}
.SubContent .Inner .Title {
  font-size: 1.6245rem;
  margin: 0;
}

.SubContent .Inner p {
  font-size: 1.5em;
}

@media (max-width: 1200px) {
  .SubContent {
    max-width: 50%;
  }
}

@media (min-width: 1200px) {
  .SubContent {
    /* border-left: 1px solid rgb(245, 243, 247); */
  }
}

@media (max-width: 750px) {
  .SubContent {
    max-width: 100%;
  }
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .SubContent .Inner {
    padding: 1.2rem;
  }
}
