@import url("https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c:300,400,500");

body {
  font-family: "M PLUS Rounded 1c", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: rgb(219, 10, 65);
}

.WebsiteBuilder {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}

html {
  scroll-behavior: smooth;
}
a {
  color: #fff;
  text-decoration: none;
}
