.Features {
  padding: 0px 1.75rem;
  margin-bottom: 61px;
}

.Features .Content {
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  transform: translateZ(0px);
  width: 100%;
  flex: 0 1 auto;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 2rem;
  box-sizing: border-box;
  flex-direction: column;
}
.Features h1 {
  color: #fff;
}

.Features .List {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.Features .List .Feature {
  width: 22%;
  padding-left: 5.5%;
  padding-right: 5.5%;
  padding-top: 2%;
  text-align: center;
}
.Features .List .Question p {
  width: 80%;
}
.Features .Feature .Icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Features .Feature .Icon img {
  height: 66px;
  filter: grayscale(100%);
}
