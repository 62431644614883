.MainContent {
  padding: 0px 1.75rem;
}

.MainContent .Content {
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  transform: translateZ(0px);
  width: 100%;
  flex: 0 1 auto;
  background: rgb(255, 255, 255);
  border-radius: 4px;
}

.MainContent .Section {
  width: 100%;
  padding: 1.75rem;
  padding-bottom: 6rem;
}

.MainContent .Section .FriendLogos {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 7em;
  margin-bottom: 7em;
  flex-wrap: wrap;
}
.MainContent .Section .FriendLogo {
  width: 33.3333%;
  display: flex;
  justify-content: center;
}

.MainContent .Section img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  height: 4em;
}

.MainContent .Content h1 {
  text-align: center;
  margin-bottom: 2.1rem;
  font-size: 1.86607rem;
  line-height: 2.8rem;
  width: 100%;
}

.MainContentBackground {
  position: absolute;
  background-color: #db0a41;
  height: calc(100% + 320px);
  width: 100%;
  padding-bottom: 100px;
  z-index: -5;
}

@media (max-width: 830px) {
  .MainContent .Section img {
    height: 2em;
  }
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .MainContent {
    padding: 0px 0.75rem;
  }
  .MainContent .Section .FriendLogo {
    width: 100%;
    margin-bottom: 45px;
  }
  .MainContent .Section img {
    height: 4em;
  }
}
