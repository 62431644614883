@font-face {
  font-family: ELFontBold;
  src: url("/BasketballStyle/Font/CoText_Trial_Rg.ttf");
}

@font-face {
  font-family: ELFontRegular;
  src: url("/BasketballStyle/Font/CoText_Trial_Lt.ttf");
}
.LeagueTable {
  transform: scale(0.346);
  transform-origin: top left;
  height: 1080px;
  width: 1920px;
  background-image: url("/BasketballStyle/Images/Background.png");
}
.LeagueTable .Background {
  width: 1080px;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.LeagueTable .Background .OrangeContent {
  background-color: #f16228;
  width: 1064px;
  height: 100%;
  clip-path: inset(0% 100% 0% 0%);
  transition: clip-path 1s cubic-bezier(0.1, 0.2, 0, 1.01),
    opacity 1s cubic-bezier(0.1, 0.2, 0, 1.01);
  position: absolute;
  display: flex;
  left: 410px;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.LeagueTable .Background .OrangeContent.Active {
  clip-path: inset(0% 0% 0% 0%);
  opacity: 1;
}
.LeagueTable .Background .OrangeContent.Loop {
  clip-path: inset(0% 99% 0% 0%);
}
.LeagueTable .Background .OrangeContent img {
  height: 40%;
}
.LeagueTable .Background .MainContent {
  display: flex;
  padding: 0;
  clip-path: inset(0% 0% 0% 100%);
  transition: clip-path 1s cubic-bezier(0.1, 0.2, 0, 1.01);
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.9) 70%,
    100%,
    rgba(51, 51, 51, 0.9) 100%
  );
  height: 1080px;
}
.LeagueTable .Background .MainContent.Active {
  clip-path: inset(0% 0% 0% 0%);
}
.LeagueTable .Background .LeftBar {
  width: 17px;
  height: 100%;
  background-color: #f16228;
  clip-path: inset(100% 0% 100% 0%);
  -webkit-transition: clip-path 1s; /* Safari */
  transition: clip-path 1s;
}

.LeagueTable .Background .LeftColumn {
  width: 702px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.LeagueTable .Background .RightColumn {
  width: 362px;
  height: 100%;
  background-image: linear-gradient(
    rgba(51, 51, 51, 0.6) 70%,
    100%,
    rgba(102, 102, 102, 0.6) 100%
  );
  display: flex;
  flex-direction: column;
}

.LeagueTable .Background .LeftColumn .LeagueTableHeader h1 {
  color: #f16228;
  font-size: 73px;
  padding-left: 10px;
  margin-top: 44px;
  line-height: 73px;
  margin-bottom: 0px;
}
.LeagueTable .Background .LeftColumn .LeagueTableHeader h2 {
  color: #ffffff;
  font-size: 47px;
  padding-left: 10px;
  line-height: 47px;
  font-weight: 100;
  margin-top: 5px;
  margin-bottom: 0px;
}
.LeagueTable .Background .RightColumn .LeagueTableHeader img.ELLogo {
  margin-left: 15px;
  margin-top: 44px;
}

.LeagueTable .Background .RightColumn .LeagueTableHeader img.Sponsor {
  margin-top: 5px;
  margin-left: auto;
  float: right;
  margin-right: 22px;
}

.LeagueTable .Background .LeftColumn .SubHeader {
  color: #f16228;
  margin-left: 10px;
  font-size: 25px;
  margin-top: 28px;
}

.LeagueTable .Background .RightColumn .SubHeader {
  display: flex;
  color: #f16228;
  font-size: 25px;
  margin-top: 20px;
}
.LeagueTable .Background .RightColumn .SubHeader .Item {
  width: 25%;
  text-align: center;
}

.LeagueTable .Background .LeftColumn .Teams {
  display: flex;
  flex-direction: column;
  color: #fff;
}
.LeagueTable .Background .LeftColumn .Teams .Row {
  display: flex;
  align-items: center;
  font-size: 38px;
  height: 78px;
}

.LeagueTable .Background .LeftColumn .Teams .Row .Position {
  width: 80px;
  text-align: center;
}
.LeagueTable .Background .LeftColumn .Teams .Row .Badge {
  height: 78px;
}
.LeagueTable .Background .LeftColumn .Teams .Row .Team {
  line-height: 32px;
  font-size: 32px;
  margin-left: 10px;
  font-weight: 100;
}

.LeagueTable .Background .RightColumn .Teams {
  color: #fff;
  display: flex;
  flex-direction: column;
}

.LeagueTable .Background .RightColumn .Teams .Row {
  display: flex;
  align-items: center;
  height: 78px;
  font-size: 27px;
  text-align: center;
  font-family: "ELFontBold";
}
.LeagueTable .Background .RightColumn .Teams .Row div {
  width: 25%;
}
