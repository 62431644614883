.SocialContent {
  padding: 1.75rem;
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .SocialContent {
    padding: 0px 0.75rem;
  }
}

.SocialContent h1 {
  color: #fff;
  padding-left: 2.2em;
}

.SocialContent .Content {
  display: flex;
  margin-bottom: 40px;
  /* flex-wrap: wrap; */
}

.SocialContent .Content.Insta {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
}

.SocialContent .Content .InstaPosts {
  display: flex;
  /* flex-wrap: wrap; */
}

.SocialContent svg {
  height: 24px;
  width: 24px;
  color: #fff;
}

.SocialContent .InstagramAvatar {
  color: #fff;
  display: flex;
  align-items: center;
}

.SocialContent .InstagramAvatarContainer {
  display: flex;
}
.SocialContent .InstagramProfileImage {
  border-radius: 50%;
  overflow: hidden;
  height: 50px;
  width: 50px;
  background-size: contain;
  margin-right: 1em;
  /* margin-left: 1em; */
}
@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .SocialContent .Content .InstaPosts {
    display: flex;
    flex-wrap: wrap;
    padding-left: 1.7em;
  }
  .SocialContent .Content,
  .SocialContent .Content.Insta {
    display: flex;
    margin-bottom: 0px;
    flex-wrap: wrap;
  }
  .SocialContent .Content {
    margin-bottom: 20px;
  }
  .SocialContent h1 {
    padding-left: 0;
  }
}
