.Header {
  position: absolute;
  background-color: transparent;
  left: 0px;
  right: 0px;
  top: calc(1.55rem);
  z-index: 2;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

.HeaderContainer {
  padding-left: 6.3rem;
  padding-right: 6.3rem;
  display: flex;
  align-items: center;
  height: calc(2.9rem);
}

.Header .LogoLink {
  -webkit-box-align: center;
  align-items: center;
  color: inherit;
  display: flex;
  margin-right: 0.7rem;
  text-decoration: none;
}

.Header .LogoLink .Logo {
  height: 1.55rem;
}

.Header .PageLinks {
  display: flex;
  margin: 0px 0px 0px 0.35rem;
}

.Header .PageLinks .Link {
  font-size: 0.9rem;
  line-height: calc(2.9rem);
  color: inherit;
  display: block;
  letter-spacing: 0.05em;
  -webkit-font-smoothing: antialiased;
  position: relative;
  text-transform: uppercase;
  top: 0px;
  z-index: 1;
  text-decoration: none;
  transition: color 250ms cubic-bezier(0.4219, 0, 0.2, 1) 0s;
  margin: 0px 0.7rem;
  cursor: pointer;
}
.Header .PageLinks .Link:hover {
  color: #db0a41;
}

.Header .Social {
  margin-left: auto;
  text-align: right;
  align-items: center;
  display: flex;
}

.Header .Social .SocialLink {
  margin-left: 32px;
}

.Header .Social svg {
  height: 1.3em;
  color: #fff;
  cursor: pointer;
}

.Header.Fixed {
  background-color: transparent;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0;
  z-index: 12;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  background-color: #fff;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  border-bottom: 1px solid #db0a41;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
}

.Header.Fixed.Dark {
  background-color: #0b162f;
}

.Header.Fixed svg {
  color: #000;
}

.Header.Fixed.Dark svg {
  color: #fff;
}

@media (max-width: 1200px) {
  .Header .PageLinks .Link {
    font-size: 0.7937rem;
  }
}
@media (max-width: 880px) {
  .Header .PageLinks {
    display: none;
  }
}

@media (max-width: 768px) {
  .Header {
    top: calc(1.55rem);
  }
  .HeaderContainer {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }
}
@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .Hero .PolygonRight {
    width: calc(160% + 4vh);
  }
  .Header .Social {
    display: none;
  }
  .Header.Fixed {
    display: none;
  }
}

@media (max-width: 1000px) {
  .HeaderContainer {
    padding-right: 1.75rem;
  }
}
