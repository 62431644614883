.PricePlanItem {
  width: 33.3%;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-left: 3em;
  padding-right: 3em;
  padding-bottom: 2em;
  font-size: 1.2em;
}
.PricePlanItem .Description:hover {
  transform: scale(1.02);
}
.PricePlanItem:nth-child(-n + 2) {
  border-right: 1px solid #e2e2e2;
}
.PricePlanItem:nth-child(4) {
  border-right: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
}
.PricePlanItem:nth-child(5) {
  border-right: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
}
.PricePlanItem:nth-child(6) {
  border-top: 1px solid #e2e2e2;
}
.PricePlanItem:nth-child(8) {
  border-top: 1px solid #e2e2e2;
}
.PricePlanItem .Price {
  font-size: 2em;
}
.PricePlanItem .Price .SubPrice {
  font-size: 0.6em;
}

.PricePlanItem button {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 0.5em;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  margin-top: 1em;
  transition: background-color 0.4s, color 0.4s;
  height: 4.5em;
}
.PricePlanItem button.Secondary {
  background-color: transparent;
  border: 1px solid #ed003d;
}
.PricePlanItem button:hover {
  background-color: #ed003d;
  color: #fff;
}
.PricePlanItem .Description {
  height: 100%;
  transition: transform 0.3s;
}
.PricePlanItem .Link {
  cursor: pointer;
}

.PricePlanItem h2 {
  /* color: #db0a41; */
}

@media (max-width: 750px) {
  .PricePlanItem {
    min-width: 100%;
  }
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .PricePlanItem .Inner {
    padding: 1.2rem;
  }
}

@media (max-width: 1200px) {
  .PricePlanItem {
    min-width: 50%;
  }
  .PricePlanItem:nth-child(-n + 2) {
    border-right: 1px solid #e2e2e2;
  }
  .PricePlanItem:nth-child(4) {
    border: none;
  }
  .PricePlanItem:nth-child(5) {
    border: none;
  }
  .PricePlanItem:nth-child(6) {
    border: none;
  }
  .PricePlanItem:nth-child(-n + 6) {
    border-top: 1px solid #e2e2e2;
  }
  .PricePlanItem:nth-child(1) {
    border-right: 1px solid #e2e2e2;
  }
  .PricePlanItem:nth-child(3) {
    border-right: 1px solid #e2e2e2;
  }
  .PricePlanItem:nth-child(5) {
    border-right: 1px solid #e2e2e2;
  }
  .MainContent2 .Content {
    padding: 0;
  }
}

@media (max-width: 750px) {
  .PricePlanItem {
    min-width: 100%;
    font-size: 1em;
  }
  .PricePlanItem:nth-child(-n + 2) {
    border: none;
  }
  .PricePlanItem:nth-child(4) {
    border: none;
  }
  .PricePlanItem:nth-child(5) {
    border: none;
  }
  .PricePlanItem:nth-child(6) {
    border: none;
  }
  .PricePlanItem:nth-child(-n + 6) {
    border-top: 1px solid #e2e2e2;
  }
  .PricePlanItem:nth-child(1) {
    border: none;
  }
  .MainContent2 .Content {
    padding: 0;
  }
}
