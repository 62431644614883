.Loading {
  text-align: center;
}

.loading {
  padding: 5em;
}
.loading-bar {
  display: inline-block;
  width: 4px;
  height: 25px;
  margin-left: 4px;
  border-radius: 4px;
  animation: loading 1s ease-in-out infinite;
}
.loading-bar:nth-child(1) {
  background-color: #262829;
  animation-delay: 0;
}
.loading-bar:nth-child(2) {
  background-color: #262829;
  animation-delay: 0.09s;
}
.loading-bar:nth-child(3) {
  background-color: #262829;
  animation-delay: 0.18s;
}
.loading-bar:nth-child(4) {
  background-color: #262829;
  animation-delay: 0.27s;
}

@keyframes loading {
  0% {
    background-color: #db0a41;
    transform: scale(1);
  }
  20% {
    background-color: #db0a41;
    transform: scale(1, 2.2);
  }
  40% {
    background-color: #262829;
    transform: scale(1);
  }
}
