.HeroButtons {
  padding-left: 6rem;
  margin-top: -4em;
  margin-bottom: 3em;
  color: #fff;
}
.HeroButtons .Buttons {
  display: flex;
}
.HeroButtons .Buttons button {
  background-color: #db0a41;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 0.5em;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  margin-right: 1em;
  transition: background-color 0.4s, color 0.4s, transform 0.4s;
  border: 1px solid transparent;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
}
.HeroButtons .Buttons button.Secondary {
  background-color: transparent;
  border: 1px solid #ed003d;
}
.HeroButtons .Buttons button.Secondary:hover {
  transform: none;
  background-color: #ed003d;
}
.HeroButtons .Buttons button:hover {
  transform: scale(1.1);
}
.HeroButtons p {
  font-weight: 100;
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .HeroButtons .Buttons button {
    background-color: #0b162f;
    width: 100%;
    margin-right: 0;
  }
  .HeroButtons {
    padding: 0px 1.75rem;
    margin-top: -2em;
    margin-bottom: 1em;
    color: #fff;
  }
}
