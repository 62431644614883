.Page {
  background-color: rgb(11, 22, 47);
  padding: 5em;
  height: 100vh;
  box-sizing: border-box;
  border-top: 1px solid #202e4c;
}

.Page .PageNum {
  color: #f5d24b;
  font-size: 2em;
  margin-right: 1em;
}

.Page .PageDetails {
  display: flex;
  color: #fff;
  align-items: center;
}

.Page .Text {
  width: 50%;
  color: #020202;
  background: #ffffff;
  line-height: 1.8em;
  padding: 1em;
  border-radius: 4px;
  font-size: 1.5em;
}

.Page .GraphicPlayer {
  width: calc(1920px / 3);
  height: calc(1080px / 3);
  overflow: hidden;
  background-color: #252f44;
  border-radius: 3px;
}
