.HoldingPage {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  text-transform: uppercase;
  position: absolute;
}

.HoldingPage img {
  height: 90%;
}
.HoldingPage .Caption {
  margin: 2em;
  letter-spacing: 0.2em;
  font-size: 2em;
}

.White {
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  position: absolute;
  color: #0b162f;
}

.Blue {
  background-color: #0b162f;
  height: 100vh;
  width: 100vw;
  position: absolute;
  color: #fff;
}
/* .HoldingPage .Launch {
  margin-top: 10%;
} */
.HoldingPage .TimeElement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: #202e4c; */
  border-radius: 4px;
  margin: 2em;
  justify-items: center;
  text-align: center;
  min-width: 80px;
  /* box-shadow: 5px 5px 4px 0px rgba(4, 4, 4, 0.1); */
}

.HoldingPage .Clock {
  display: flex;
  width: 100%;
  justify-content: center;
}

.HoldingPage .TimeElement .Value {
  font-size: 1.5em;
  font-weight: 500;
  color: rgb(245, 210, 75);
}

.HoldingPage .Divide {
  background-color: #070d1b;
  width: 1px;
  height: 61px;
  margin-top: 49px;
}

.HoldingPage h1 {
  color: #fff;
  width: 100%;
  text-align: center;
  display: none;
  outline: 1px solid #0b162f;
  box-shadow: 0 0 1px rgb(11, 22, 47);
  -webkit-backface-visibility: hidden;
}

/* span {
  border-right: 0.05em solid;
  animation: caret 1s steps(1) infinite;
} */

@keyframes caret {
  50% {
    border-color: transparent;
  }
}

.HoldingPage .Grow {
  display: block;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  transition: all 2s ease-out;
}
.HoldingPage .Grow.Active {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

.HoldingPage .Logo {
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  transition: all 0.5s ease-out;
  height: 15%;
  position: absolute;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 15%;
}
.HoldingPage .Logo.Main {
  height: 15%;
}

.HoldingPage .Logo.Active {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

.HoldingPage .Clock {
  visibility: hidden;
  opacity: 0;
  transition: all 2s ease-out;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 40%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.HoldingPage .Clock.Active {
  visibility: visible;
  opacity: 1;
}

.HoldingPage .LaunchText {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 40%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  transition: all 2s ease-out;
}

.HoldingPage .LaunchText.Active {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .HoldingPage .Logo.Main,
  .HoldingPage .Logo {
    height: auto;
    width: 90%;
  }
  .HoldingPage .Clock,
  .HoldingPage .LaunchText {
    top: 30%;
  }
}

.HoldingPage .Social {
  display: flex;
  position: absolute;
  top: 1%;
  justify-content: center;
}

.HoldingPage .Social .SocialLink {
  margin-left: 15px;
  margin-right: 15px;
  width: 15px;
  visibility: hidden;
  opacity: 0;
  transition: all 2s ease-out;
}

.HoldingPage .SocialLink.Active {
  visibility: visible;
  opacity: 1;
}
