.PricePlan {
  padding: 0px 1.75rem;
}

.PricePlan .Content {
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  transform: translateZ(0px);
  width: 100%;
  flex: 0 1 auto;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 2rem;
  box-sizing: border-box;
}

.PricePlan .Bespoke {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  color: #fff;
}

.PricePlan .Bespoke button {
  background-color: #fbd21a;
  color: #081631;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 0.5em;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  margin-left: 1em;
  -webkit-transition: background-color 0.4s, color 0.4s;
  transition: background-color 0.4s, color 0.4s;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
}

@media (max-width: 830px) {
  .PricePlan .Section img {
    height: 2em;
  }
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .PricePlan {
    padding: 0px 0.75rem;
  }
  .PricePlan .Section .FriendLogo {
    width: 100%;
    margin-bottom: 45px;
  }
  .PricePlan .Section img {
    height: 4em;
  }
}
