.Questions {
  padding: 0px 1.75rem;
  margin-bottom: 163px;
}

.Questions .Content {
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  transform: translateZ(0px);
  width: 100%;
  flex: 0 1 auto;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 2rem;
  box-sizing: border-box;
  flex-direction: column;
}
.Questions h1 {
  color: #fff;
}

.Questions .List {
  display: flex;
  flex-wrap: wrap;
}

.Questions .List .Question {
  width: 23%;
  padding-left: 1%;
  padding-right: 1%;
}
.Questions .List .Question p {
  width: 80%;
}
