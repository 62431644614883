.TwitterBlock {
  background-color: #fff;
  border-radius: 4px;
  /* width: 20%; */
  padding: 10px;
  margin-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px;
  /* width: 25.3333%; */
  margin-top: 1.5em;
}

.TwitterBlock .TwitterComment {
  font-weight: 100;
  margin-top: 0.3em;
}

.TwitterBlock .TwitterAvatar {
  color: #000;
  display: flex;
  align-items: center;
}

.TwitterBlock .TwitterProfileImage {
  border-radius: 50%;
  overflow: hidden;
  height: 34px;
  width: 34px;
  background-size: contain;
  margin-right: 0.2em;
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .TwitterBlock {
    width: 100%;
    margin-bottom: 0px;
    margin-right: 0px;
  }
}
