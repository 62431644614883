.CookieContent {
  padding: 0px 1.75rem;
  margin-bottom: 163px;
  text-align: center;
}

.CookieContent .Content {
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  transform: translateZ(0px);
  width: 100%;
  flex: 0 1 auto;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 4.2rem;
  box-sizing: border-box;
}

.CookieContent .Content .CookieMonster {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  margin-left: auto;
  margin-right: auto;
}

.CookieContent .Content h1 {
  text-align: center;
  margin-bottom: 2.1rem;
  font-size: 1.86607rem;
  line-height: 2.8rem;
  width: 100%;
}

.CookieBackgroundContainer {
}

.CookieBackground {
  position: absolute;
  background-color: #db0a41;
  height: calc(100% + 320px);
  width: 100%;
  padding-bottom: 100px;
  z-index: -5;
}
@media (max-width: 750px) {
  .CookieContent {
    padding: 0px 0.75rem;
    margin-bottom: 43px;
  }

  .CookieContent .Content {
    padding: 0px;
  }

  .CookieContent .Content .CookieMonster {
    width: 80%;
    height: 80%;
  }
}
