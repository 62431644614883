.Stages {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: 50px;
  align-items: center;
}

.Stages .Stage {
  width: 33.3%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Stages .Stage .Circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  font-weight: 100;
  margin-left: auto;
  margin-right: auto;
}

.Stages .Stage .Circle.Complete {
  background-color: #26ddac;
}

.Stages .Stage .Circle.Active {
  border: 1px solid #26ddac;
}

.Stages .Stage .Title {
  padding: 0.5em;
}
.Stages .Line {
  width: 100%;
  height: 5px;
  background-color: whitesmoke;
  margin-bottom: 22px;
  border-radius: 4px;
}
.Stages .Line .Progress {
  height: 100%;
  background-color: #26ddac;
  border-radius: 4px;
}
