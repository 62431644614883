.InstagramBlock {
  margin-top: 1.5em;
  margin-right: 1.5em;
  position: relative;
}

.InstagramBlock .InstagramImage img {
  width: 357px;
  border-radius: 6px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
}

.InstagramBlock .InstagramOverlay {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(219, 10, 65, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  transition: opacity 0.3s;
}
.InstagramBlock .InstagramOverlay:hover {
  opacity: 1;
}
@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .InstagramBlock {
    width: 100%;
    margin-bottom: 0px;
  }
}
