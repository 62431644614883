@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;1,300;1,400&display=swap);
@import url(https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c:300,400,500);
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea {
  font-family: M PLUS rounded\1c, sans-serif;
}

body {
  font-family: "M PLUS Rounded 1c", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: rgb(219, 10, 65);
}

.WebsiteBuilder {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}

html {
  scroll-behavior: smooth;
}
a {
  color: #fff;
  text-decoration: none;
}

.HoldingPage {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  text-transform: uppercase;
  position: absolute;
}

.HoldingPage img {
  height: 90%;
}
.HoldingPage .Caption {
  margin: 2em;
  letter-spacing: 0.2em;
  font-size: 2em;
}

.White {
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  position: absolute;
  color: #0b162f;
}

.Blue {
  background-color: #0b162f;
  height: 100vh;
  width: 100vw;
  position: absolute;
  color: #fff;
}
/* .HoldingPage .Launch {
  margin-top: 10%;
} */
.HoldingPage .TimeElement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: #202e4c; */
  border-radius: 4px;
  margin: 2em;
  justify-items: center;
  text-align: center;
  min-width: 80px;
  /* box-shadow: 5px 5px 4px 0px rgba(4, 4, 4, 0.1); */
}

.HoldingPage .Clock {
  display: flex;
  width: 100%;
  justify-content: center;
}

.HoldingPage .TimeElement .Value {
  font-size: 1.5em;
  font-weight: 500;
  color: rgb(245, 210, 75);
}

.HoldingPage .Divide {
  background-color: #070d1b;
  width: 1px;
  height: 61px;
  margin-top: 49px;
}

.HoldingPage h1 {
  color: #fff;
  width: 100%;
  text-align: center;
  display: none;
  outline: 1px solid #0b162f;
  box-shadow: 0 0 1px rgb(11, 22, 47);
  -webkit-backface-visibility: hidden;
}

/* span {
  border-right: 0.05em solid;
  animation: caret 1s steps(1) infinite;
} */

@-webkit-keyframes caret {
  50% {
    border-color: transparent;
  }
}

@keyframes caret {
  50% {
    border-color: transparent;
  }
}

.HoldingPage .Grow {
  display: block;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 2s ease-out;
}
.HoldingPage .Grow.Active {
  -webkit-transform: scale(1);
          transform: scale(1);
  visibility: visible;
  opacity: 1;
}

.HoldingPage .Logo {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 0.5s ease-out;
  height: 15%;
  position: absolute;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 15%;
}
.HoldingPage .Logo.Main {
  height: 15%;
}

.HoldingPage .Logo.Active {
  -webkit-transform: scale(1);
          transform: scale(1);
  visibility: visible;
  opacity: 1;
}

.HoldingPage .Clock {
  visibility: hidden;
  opacity: 0;
  transition: all 2s ease-out;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 40%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.HoldingPage .Clock.Active {
  visibility: visible;
  opacity: 1;
}

.HoldingPage .LaunchText {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 40%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  transition: all 2s ease-out;
}

.HoldingPage .LaunchText.Active {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .HoldingPage .Logo.Main,
  .HoldingPage .Logo {
    height: auto;
    width: 90%;
  }
  .HoldingPage .Clock,
  .HoldingPage .LaunchText {
    top: 30%;
  }
}

.HoldingPage .Social {
  display: flex;
  position: absolute;
  top: 1%;
  justify-content: center;
}

.HoldingPage .Social .SocialLink {
  margin-left: 15px;
  margin-right: 15px;
  width: 15px;
  visibility: hidden;
  opacity: 0;
  transition: all 2s ease-out;
}

.HoldingPage .SocialLink.Active {
  visibility: visible;
  opacity: 1;
}

.MainContent2 {
  padding: 0px 1.75rem;
}

.MainContent2 .Content {
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  -webkit-transform: translateZ(0px);
          transform: translateZ(0px);
  width: 100%;
  flex: 0 1 auto;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 2rem;
  box-sizing: border-box;
}

.MainContent2 .Bespoke {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  color: #fff;
}

.MainContent2 .Bespoke button {
  background-color: #fbd21a;
  color: #081631;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 0.5em;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  margin-left: 1em;
  transition: background-color 0.4s, color 0.4s;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
}
@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .MainContent2 {
    padding: 0.75em;
  }
}

.SubContent {
  max-width: 33.3333%;
  box-shadow: rgb(245, 243, 247) 0px 1px 0px 0px;
  border-left: 0px solid rgb(245, 243, 247);
  box-sizing: border-box;
}

@media (min-width: 750px) {
  .SubContent:nth-child(2n) {
    border-left: 1px solid rgb(245, 243, 247);
  }

  .SubContent:nth-child(3n) {
    border-left: 1px solid #f5f3f7;
  }
  .SubContent:nth-child(5n) {
    border-left: 1px solid #f5f3f7;
  }
}

.SubContent .Inner {
  padding: 4.2rem;
}
.SubContent .Inner .Title {
  font-size: 1.6245rem;
  margin: 0;
}

.SubContent .Inner p {
  font-size: 1.5em;
}

@media (max-width: 1200px) {
  .SubContent {
    max-width: 50%;
  }
}

@media (min-width: 1200px) {
  .SubContent {
    /* border-left: 1px solid rgb(245, 243, 247); */
  }
}

@media (max-width: 750px) {
  .SubContent {
    max-width: 100%;
  }
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .SubContent .Inner {
    padding: 1.2rem;
  }
}

.PricePlanItem {
  width: 33.3%;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-left: 3em;
  padding-right: 3em;
  padding-bottom: 2em;
  font-size: 1.2em;
}
.PricePlanItem .Description:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}
.PricePlanItem:nth-child(-n + 2) {
  border-right: 1px solid #e2e2e2;
}
.PricePlanItem:nth-child(4) {
  border-right: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
}
.PricePlanItem:nth-child(5) {
  border-right: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
}
.PricePlanItem:nth-child(6) {
  border-top: 1px solid #e2e2e2;
}
.PricePlanItem:nth-child(8) {
  border-top: 1px solid #e2e2e2;
}
.PricePlanItem .Price {
  font-size: 2em;
}
.PricePlanItem .Price .SubPrice {
  font-size: 0.6em;
}

.PricePlanItem button {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 0.5em;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  margin-top: 1em;
  transition: background-color 0.4s, color 0.4s;
  height: 4.5em;
}
.PricePlanItem button.Secondary {
  background-color: transparent;
  border: 1px solid #ed003d;
}
.PricePlanItem button:hover {
  background-color: #ed003d;
  color: #fff;
}
.PricePlanItem .Description {
  height: 100%;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.PricePlanItem .Link {
  cursor: pointer;
}

.PricePlanItem h2 {
  /* color: #db0a41; */
}

@media (max-width: 750px) {
  .PricePlanItem {
    min-width: 100%;
  }
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .PricePlanItem .Inner {
    padding: 1.2rem;
  }
}

@media (max-width: 1200px) {
  .PricePlanItem {
    min-width: 50%;
  }
  .PricePlanItem:nth-child(-n + 2) {
    border-right: 1px solid #e2e2e2;
  }
  .PricePlanItem:nth-child(4) {
    border: none;
  }
  .PricePlanItem:nth-child(5) {
    border: none;
  }
  .PricePlanItem:nth-child(6) {
    border: none;
  }
  .PricePlanItem:nth-child(-n + 6) {
    border-top: 1px solid #e2e2e2;
  }
  .PricePlanItem:nth-child(1) {
    border-right: 1px solid #e2e2e2;
  }
  .PricePlanItem:nth-child(3) {
    border-right: 1px solid #e2e2e2;
  }
  .PricePlanItem:nth-child(5) {
    border-right: 1px solid #e2e2e2;
  }
  .MainContent2 .Content {
    padding: 0;
  }
}

@media (max-width: 750px) {
  .PricePlanItem {
    min-width: 100%;
    font-size: 1em;
  }
  .PricePlanItem:nth-child(-n + 2) {
    border: none;
  }
  .PricePlanItem:nth-child(4) {
    border: none;
  }
  .PricePlanItem:nth-child(5) {
    border: none;
  }
  .PricePlanItem:nth-child(6) {
    border: none;
  }
  .PricePlanItem:nth-child(-n + 6) {
    border-top: 1px solid #e2e2e2;
  }
  .PricePlanItem:nth-child(1) {
    border: none;
  }
  .MainContent2 .Content {
    padding: 0;
  }
}

.SocialContent {
  padding: 1.75rem;
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .SocialContent {
    padding: 0px 0.75rem;
  }
}

.SocialContent h1 {
  color: #fff;
  padding-left: 2.2em;
}

.SocialContent .Content {
  display: flex;
  margin-bottom: 40px;
  /* flex-wrap: wrap; */
}

.SocialContent .Content.Insta {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
}

.SocialContent .Content .InstaPosts {
  display: flex;
  /* flex-wrap: wrap; */
}

.SocialContent svg {
  height: 24px;
  width: 24px;
  color: #fff;
}

.SocialContent .InstagramAvatar {
  color: #fff;
  display: flex;
  align-items: center;
}

.SocialContent .InstagramAvatarContainer {
  display: flex;
}
.SocialContent .InstagramProfileImage {
  border-radius: 50%;
  overflow: hidden;
  height: 50px;
  width: 50px;
  background-size: contain;
  margin-right: 1em;
  /* margin-left: 1em; */
}
@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .SocialContent .Content .InstaPosts {
    display: flex;
    flex-wrap: wrap;
    padding-left: 1.7em;
  }
  .SocialContent .Content,
  .SocialContent .Content.Insta {
    display: flex;
    margin-bottom: 0px;
    flex-wrap: wrap;
  }
  .SocialContent .Content {
    margin-bottom: 20px;
  }
  .SocialContent h1 {
    padding-left: 0;
  }
}

.InstagramBlock {
  margin-top: 1.5em;
  margin-right: 1.5em;
  position: relative;
}

.InstagramBlock .InstagramImage img {
  width: 357px;
  border-radius: 6px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
}

.InstagramBlock .InstagramOverlay {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(219, 10, 65, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  transition: opacity 0.3s;
}
.InstagramBlock .InstagramOverlay:hover {
  opacity: 1;
}
@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .InstagramBlock {
    width: 100%;
    margin-bottom: 0px;
  }
}

.TwitterBlock {
  background-color: #fff;
  border-radius: 4px;
  /* width: 20%; */
  padding: 10px;
  margin-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px;
  /* width: 25.3333%; */
  margin-top: 1.5em;
}

.TwitterBlock .TwitterComment {
  font-weight: 100;
  margin-top: 0.3em;
}

.TwitterBlock .TwitterAvatar {
  color: #000;
  display: flex;
  align-items: center;
}

.TwitterBlock .TwitterProfileImage {
  border-radius: 50%;
  overflow: hidden;
  height: 34px;
  width: 34px;
  background-size: contain;
  margin-right: 0.2em;
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .TwitterBlock {
    width: 100%;
    margin-bottom: 0px;
    margin-right: 0px;
  }
}

.LowerSection {
  padding: 0px 1.75rem;
  margin-top: 3em;
}
.LowerSection.Social {
  margin-bottom: 3em;
  margin-top: 0px;
}
@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .LowerSection {
    padding: 0px 0.75rem;
  }
}

.LowerSection .Content {
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  -webkit-transform: translateZ(0px);
          transform: translateZ(0px);
  width: 100%;
  flex: 0 1 auto;
  background: rgb(255, 255, 255);
  border-radius: 4px;
}

.AboutContent {
  text-align: center;
  padding: 0px 1.75rem;
}

.AboutContent p {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-weight: 100;
  margin-bottom: 59px;
  font-size: 1.4em;
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .AboutContent p {
    max-width: 100%;
  }
  .AboutContent {
    padding: 0px 0.75rem;
  }
}

.AboutContent p.HyperColour1 {
  color: #db0a41;
  /* font-weight: 400; */
  font-size: 1.6em;
}

.AboutUsPara {
  display: flex;
  flex-wrap: wrap;
}
.AboutUsPara .LeftAbout,
.AboutUsPara .RightAbout {
  /* width: 50%; */
  padding: 4em;
  text-align: left;
  font-size: 1.6em;
  margin-bottom: 0px;
  box-sizing: border-box;
}

.H {
  color: #db0a41;
  font-weight: 400;
}
.AboutUsPara .LeftAbout {
  font-size: 1.6em;
}

.AboutSplitter {
  width: 10%;
  height: 2px;
  border-radius: 4px;
  background-color: #db0a41;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1200px) {
  .AboutContent p {
    max-width: 100%;
  }
  .AboutContent p.HyperColour1 {
    font-size: 1.4em;
  }
  .AboutUsPara .LeftAbout,
  .AboutUsPara .RightAbout {
    padding: 2em;
    text-align: left;
    font-size: 1.2em;
    margin-bottom: 0px;
    width: 41%;
  }
}

@media (max-width: 750px) {
  .AboutUsPara .LeftAbout,
  .AboutUsPara .RightAbout {
    width: 100%;
  }
  .AboutContent p.HyperColour1 {
    font-size: 1.2em;
  }
  .AboutUsPara .LeftAbout,
  .AboutUsPara .RightAbout {
    font-size: 1.2em;
    padding: 1em;
  }
}

.Loading {
  text-align: center;
}

.loading {
  padding: 5em;
}
.loading-bar {
  display: inline-block;
  width: 4px;
  height: 25px;
  margin-left: 4px;
  border-radius: 4px;
  -webkit-animation: loading 1s ease-in-out infinite;
          animation: loading 1s ease-in-out infinite;
}
.loading-bar:nth-child(1) {
  background-color: #262829;
  -webkit-animation-delay: 0;
          animation-delay: 0;
}
.loading-bar:nth-child(2) {
  background-color: #262829;
  -webkit-animation-delay: 0.09s;
          animation-delay: 0.09s;
}
.loading-bar:nth-child(3) {
  background-color: #262829;
  -webkit-animation-delay: 0.18s;
          animation-delay: 0.18s;
}
.loading-bar:nth-child(4) {
  background-color: #262829;
  -webkit-animation-delay: 0.27s;
          animation-delay: 0.27s;
}

@-webkit-keyframes loading {
  0% {
    background-color: #db0a41;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    background-color: #db0a41;
    -webkit-transform: scale(1, 2.2);
            transform: scale(1, 2.2);
  }
  40% {
    background-color: #262829;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes loading {
  0% {
    background-color: #db0a41;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    background-color: #db0a41;
    -webkit-transform: scale(1, 2.2);
            transform: scale(1, 2.2);
  }
  40% {
    background-color: #262829;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.ContactContent {
  text-align: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3em;
  margin-top: 2em;
}

.ContactContent input,
.ContactContent textarea {
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  width: 20%;
  font-size: 1.5em;
  outline: none;
  padding: 0.3em;
  color: #db0a41;
  font-weight: 100;
  margin: 0.5em;
  width: 43%;
}

.ContactContent input.Error,
.ContactContent textarea.Error {
  border: 1px solid #db0a41;
}

.ContactContent .Form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}

.ContactContent .Form textarea.Message {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.ContactContent .Form button {
  background-color: #ed003d;
  color: #fff;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1em;
  padding: 0.5em 1em;
  transition: background-color 0.4s, color 0.4s, -webkit-transform 0.4s;
  transition: background-color 0.4s, color 0.4s, transform 0.4s;
  transition: background-color 0.4s, color 0.4s, transform 0.4s, -webkit-transform 0.4s;
  transition: background-color 0.4s, color 0.4s, transform 0.4s,
    -webkit-transform 0.4s;
  border: 1px solid transparent;
  box-shadow: 0 5px 20px rgba(25, 17, 34, 0.1);
  margin: auto;
  width: 47%;
}

.ContactContent .Form button:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.ContactContent .Response {
  font-size: 1.3em;
  font-weight: 100;
  padding: 5em;
}
@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .ContactContent input,
  .ContactContent textarea {
    width: 90%;
  }
  .ContactContent {
    width: 100%;
  }
}
.ContactContent .EmailAddress {
  color: #db0a41;
  font-size: 1.6em;
}

.Footer {
  background-color: #db0a41;
  color: #fff;
  padding-top: 1em;
  padding-left: 5em;
  padding-right: 5em;
  font-weight: 100;
  display: flex;
  flex-direction: column;
}

.Footer .Lists {
  display: flex;
  margin-bottom: 3em;
  margin-left: auto;
}
.Footer .Lists .List {
  margin-left: 100px;
  text-align: left;
  font-size: 0.7em;
  font-weight: 400;
  line-height: 1.7em;
}
.Footer img {
  height: 40px;
}
.Footer .Copyright {
  text-align: center;
  font-size: 0.7em;
  margin-bottom: 5px;
}

.Footer .Container {
  display: flex;
  align-items: center;
}

@media (max-width: 1100px) {
  .Footer .Lists .List {
    font-size: 0.5em;
    margin-left: 60px;
  }
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .Footer {
    padding-left: 0;
    padding-right: 0;
  }
  .Footer .Lists,
  .Footer .Container {
    flex-direction: column;
  }
  .Footer .Lists {
    margin-left: 0;
  }
  .Footer .Lists .List {
    font-size: 0.8em;
    text-align: center;
    margin-left: 0px;
  }
}

.AboutFooter {
  cursor: pointer;
}

.Hero .Polygons {
  overflow: hidden;
  width: 100%;
  z-index: -1;
  height: 100%;
  position: absolute;
}

.Hero .Polygons svg {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -3;
}

.Hero .PolygonLeft {
  position: absolute;
  z-index: -2;
}

.Hero .PolygonRight {
  position: absolute;
  z-index: -2;
}

.Hero .HeroContent {
  padding-top: 7rem;
  padding-left: 6rem;
  padding-bottom: 4.2rem;
}

.Hero .HeroContent .Text {
  font-size: 3.03143rem;
  width: 41rem;
}

.Hero .Background {
  width: 100vw;
  height: 510px;
  position: absolute;
  z-index: -5;
}

@media (max-width: 1152px) {
  .Hero .PolygonInner {
    display: none;
  }
  .Hero .HeroContent .Text {
    font-size: 2.03143rem;
    width: 20rem;
  }
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .Hero .HeroContent {
    padding-left: 1.2rem;
    padding-bottom: 2.2rem;
    padding-top: 5rem;
  }

  .Hero .HeroContent .Text {
    font-size: 1.73143rem;
    width: 20rem;
  }
}

.Header {
  position: absolute;
  background-color: transparent;
  left: 0px;
  right: 0px;
  top: calc(1.55rem);
  z-index: 2;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

.HeaderContainer {
  padding-left: 6.3rem;
  padding-right: 6.3rem;
  display: flex;
  align-items: center;
  height: calc(2.9rem);
}

.Header .LogoLink {
  align-items: center;
  color: inherit;
  display: flex;
  margin-right: 0.7rem;
  text-decoration: none;
}

.Header .LogoLink .Logo {
  height: 1.55rem;
}

.Header .PageLinks {
  display: flex;
  margin: 0px 0px 0px 0.35rem;
}

.Header .PageLinks .Link {
  font-size: 0.9rem;
  line-height: calc(2.9rem);
  color: inherit;
  display: block;
  letter-spacing: 0.05em;
  -webkit-font-smoothing: antialiased;
  position: relative;
  text-transform: uppercase;
  top: 0px;
  z-index: 1;
  text-decoration: none;
  transition: color 250ms cubic-bezier(0.4219, 0, 0.2, 1) 0s;
  margin: 0px 0.7rem;
  cursor: pointer;
}
.Header .PageLinks .Link:hover {
  color: #db0a41;
}

.Header .Social {
  margin-left: auto;
  text-align: right;
  align-items: center;
  display: flex;
}

.Header .Social .SocialLink {
  margin-left: 32px;
}

.Header .Social svg {
  height: 1.3em;
  color: #fff;
  cursor: pointer;
}

.Header.Fixed {
  background-color: transparent;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0;
  z-index: 12;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  background-color: #fff;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  border-bottom: 1px solid #db0a41;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
}

.Header.Fixed.Dark {
  background-color: #0b162f;
}

.Header.Fixed svg {
  color: #000;
}

.Header.Fixed.Dark svg {
  color: #fff;
}

@media (max-width: 1200px) {
  .Header .PageLinks .Link {
    font-size: 0.7937rem;
  }
}
@media (max-width: 880px) {
  .Header .PageLinks {
    display: none;
  }
}

@media (max-width: 768px) {
  .Header {
    top: calc(1.55rem);
  }
  .HeaderContainer {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }
}
@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .Hero .PolygonRight {
    width: calc(160% + 4vh);
  }
  .Header .Social {
    display: none;
  }
  .Header.Fixed {
    display: none;
  }
}

@media (max-width: 1000px) {
  .HeaderContainer {
    padding-right: 1.75rem;
  }
}

.HeroButtons {
  padding-left: 6rem;
  margin-top: -4em;
  margin-bottom: 3em;
  color: #fff;
}
.HeroButtons .Buttons {
  display: flex;
}
.HeroButtons .Buttons button {
  background-color: #db0a41;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 0.5em;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  margin-right: 1em;
  transition: background-color 0.4s, color 0.4s, -webkit-transform 0.4s;
  transition: background-color 0.4s, color 0.4s, transform 0.4s;
  transition: background-color 0.4s, color 0.4s, transform 0.4s, -webkit-transform 0.4s;
  border: 1px solid transparent;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
}
.HeroButtons .Buttons button.Secondary {
  background-color: transparent;
  border: 1px solid #ed003d;
}
.HeroButtons .Buttons button.Secondary:hover {
  -webkit-transform: none;
          transform: none;
  background-color: #ed003d;
}
.HeroButtons .Buttons button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.HeroButtons p {
  font-weight: 100;
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .HeroButtons .Buttons button {
    background-color: #0b162f;
    width: 100%;
    margin-right: 0;
  }
  .HeroButtons {
    padding: 0px 1.75rem;
    margin-top: -2em;
    margin-bottom: 1em;
    color: #fff;
  }
}

.MainContent {
  padding: 0px 1.75rem;
}

.MainContent .Content {
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  -webkit-transform: translateZ(0px);
          transform: translateZ(0px);
  width: 100%;
  flex: 0 1 auto;
  background: rgb(255, 255, 255);
  border-radius: 4px;
}

.MainContent .Section {
  width: 100%;
  padding: 1.75rem;
  padding-bottom: 6rem;
}

.MainContent .Section .FriendLogos {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 7em;
  margin-bottom: 7em;
  flex-wrap: wrap;
}
.MainContent .Section .FriendLogo {
  width: 33.3333%;
  display: flex;
  justify-content: center;
}

.MainContent .Section img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  height: 4em;
}

.MainContent .Content h1 {
  text-align: center;
  margin-bottom: 2.1rem;
  font-size: 1.86607rem;
  line-height: 2.8rem;
  width: 100%;
}

.MainContentBackground {
  position: absolute;
  background-color: #db0a41;
  height: calc(100% + 320px);
  width: 100%;
  padding-bottom: 100px;
  z-index: -5;
}

@media (max-width: 830px) {
  .MainContent .Section img {
    height: 2em;
  }
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .MainContent {
    padding: 0px 0.75rem;
  }
  .MainContent .Section .FriendLogo {
    width: 100%;
    margin-bottom: 45px;
  }
  .MainContent .Section img {
    height: 4em;
  }
}

.PrivacyContent {
  padding: 0px 1.75rem;
  margin-bottom: 163px;
}

.PrivacyContent .Content {
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  -webkit-transform: translateZ(0px);
          transform: translateZ(0px);
  width: 100%;
  flex: 0 1 auto;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 4.2rem;
  box-sizing: border-box;
}

.PrivacyContent .Content h1 {
  text-align: center;
  margin-bottom: 2.1rem;
  font-size: 1.86607rem;
  line-height: 2.8rem;
  width: 100%;
}

.PrivacyBackgroundContainer {
}

.PrivacyBackground {
  position: absolute;
  background-color: #db0a41;
  height: calc(100% + 320px);
  width: 100%;
  padding-bottom: 100px;
  z-index: -5;
}

.CookieContent {
  padding: 0px 1.75rem;
  margin-bottom: 163px;
  text-align: center;
}

.CookieContent .Content {
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  -webkit-transform: translateZ(0px);
          transform: translateZ(0px);
  width: 100%;
  flex: 0 1 auto;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 4.2rem;
  box-sizing: border-box;
}

.CookieContent .Content .CookieMonster {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  margin-left: auto;
  margin-right: auto;
}

.CookieContent .Content h1 {
  text-align: center;
  margin-bottom: 2.1rem;
  font-size: 1.86607rem;
  line-height: 2.8rem;
  width: 100%;
}

.CookieBackgroundContainer {
}

.CookieBackground {
  position: absolute;
  background-color: #db0a41;
  height: calc(100% + 320px);
  width: 100%;
  padding-bottom: 100px;
  z-index: -5;
}
@media (max-width: 750px) {
  .CookieContent {
    padding: 0px 0.75rem;
    margin-bottom: 43px;
  }

  .CookieContent .Content {
    padding: 0px;
  }

  .CookieContent .Content .CookieMonster {
    width: 80%;
    height: 80%;
  }
}

.PricePlan {
  padding: 0px 1.75rem;
}

.PricePlan .Content {
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  -webkit-transform: translateZ(0px);
          transform: translateZ(0px);
  width: 100%;
  flex: 0 1 auto;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 2rem;
  box-sizing: border-box;
}

.PricePlan .Bespoke {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  color: #fff;
}

.PricePlan .Bespoke button {
  background-color: #fbd21a;
  color: #081631;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 0.5em;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  margin-left: 1em;
  transition: background-color 0.4s, color 0.4s;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
}

@media (max-width: 830px) {
  .PricePlan .Section img {
    height: 2em;
  }
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .PricePlan {
    padding: 0px 0.75rem;
  }
  .PricePlan .Section .FriendLogo {
    width: 100%;
    margin-bottom: 45px;
  }
  .PricePlan .Section img {
    height: 4em;
  }
}

.Questions {
  padding: 0px 1.75rem;
  margin-bottom: 163px;
}

.Questions .Content {
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  -webkit-transform: translateZ(0px);
          transform: translateZ(0px);
  width: 100%;
  flex: 0 1 auto;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 2rem;
  box-sizing: border-box;
  flex-direction: column;
}
.Questions h1 {
  color: #fff;
}

.Questions .List {
  display: flex;
  flex-wrap: wrap;
}

.Questions .List .Question {
  width: 23%;
  padding-left: 1%;
  padding-right: 1%;
}
.Questions .List .Question p {
  width: 80%;
}

.Features {
  padding: 0px 1.75rem;
  margin-bottom: 61px;
}

.Features .Content {
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  -webkit-transform: translateZ(0px);
          transform: translateZ(0px);
  width: 100%;
  flex: 0 1 auto;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 2rem;
  box-sizing: border-box;
  flex-direction: column;
}
.Features h1 {
  color: #fff;
}

.Features .List {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.Features .List .Feature {
  width: 22%;
  padding-left: 5.5%;
  padding-right: 5.5%;
  padding-top: 2%;
  text-align: center;
}
.Features .List .Question p {
  width: 80%;
}
.Features .Feature .Icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Features .Feature .Icon img {
  height: 66px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.WebsiteBuilderHeader {
  background-color: transparent;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
}

.WebsiteBuilderHeader .HeaderContainer {
  display: flex;
  align-items: center;
  height: calc(2.9rem);
  padding: 0;
  padding-left: 1em;
  font-size: 29px;
  font-weight: 100;
}

.WebsiteBuilderHeader .LogoLink {
  align-items: center;
  color: inherit;
  display: flex;
  margin-right: 0.7rem;
  text-decoration: none;
}

.WebsiteBuilderHeader .LogoLink .Logo {
  height: 1.55rem;
}

.WebsiteBuilderHeader .PageLinks {
  display: flex;
  margin: 0px 0px 0px 0.35rem;
}

.WebsiteBuilderHeader .PageLinks .Link {
  font-size: 0.9rem;
  line-height: calc(2.9rem);
  color: inherit;
  display: block;
  letter-spacing: 0.05em;
  -webkit-font-smoothing: antialiased;
  position: relative;
  text-transform: uppercase;
  top: 0px;
  z-index: 1;
  text-decoration: none;
  transition: color 250ms cubic-bezier(0.4219, 0, 0.2, 1) 0s;
  margin: 0px 0.7rem;
  cursor: pointer;
}
.WebsiteBuilderHeader .PageLinks .Link:hover {
  color: #db0a41;
}

.WebsiteBuilderHeader .Social {
  margin-left: auto;
  text-align: right;
  align-items: center;
  display: flex;
}

.WebsiteBuilderHeader .Social .SocialLink {
  margin-left: 32px;
}

.WebsiteBuilderHeader .Social svg {
  height: 1.3em;
  color: #fff;
  cursor: pointer;
}

.WebsiteBuilderHeader.Fixed {
  background-color: transparent;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0;
  z-index: 12;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  background-color: #fff;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  border-bottom: 1px solid #db0a41;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
}

.WebsiteBuilderHeader.Fixed.Dark {
  background-color: #0b162f;
}

.WebsiteBuilderHeader.Fixed svg {
  color: #000;
}

.WebsiteBuilderHeader.Fixed.Dark svg {
  color: #fff;
}

@media (max-width: 1200px) {
  .Header .PageLinks .Link {
    font-size: 0.7937rem;
  }
}
@media (max-width: 880px) {
  .WebsiteBuilderHeader .PageLinks {
    display: none;
  }
}

@media (max-width: 768px) {
  .WebsiteBuilderHeader {
    top: calc(1.55rem);
  }
  .HeaderContainer {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }
}
@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .Hero .PolygonRight {
    width: calc(160% + 4vh);
  }
  .WebsiteBuilderHeader .Social {
    display: none;
  }
  .WebsiteBuilderHeader.Fixed {
    display: none;
  }
}

@media (max-width: 1000px) {
  .HeaderContainer {
    padding-right: 1.75rem;
  }
}

.Stages {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: 50px;
  align-items: center;
}

.Stages .Stage {
  width: 33.3%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Stages .Stage .Circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  font-weight: 100;
  margin-left: auto;
  margin-right: auto;
}

.Stages .Stage .Circle.Complete {
  background-color: #26ddac;
}

.Stages .Stage .Circle.Active {
  border: 1px solid #26ddac;
}

.Stages .Stage .Title {
  padding: 0.5em;
}
.Stages .Line {
  width: 100%;
  height: 5px;
  background-color: whitesmoke;
  margin-bottom: 22px;
  border-radius: 4px;
}
.Stages .Line .Progress {
  height: 100%;
  background-color: #26ddac;
  border-radius: 4px;
}

.Stage1 {
  width: 40%;
  border-radius: 8px;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5em;
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.Stage1 .Form input,
.Stage1 .Form textarea {
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  width: 20%;
  font-size: 1.5em;
  outline: none;
  padding: 0.3em;
  color: #db0a41;
  font-weight: 100;
  margin: 0.5em;
  width: 75%;
}

.Stage1 .Form .Item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Stage1 .Form .Item.Top {
  align-items: baseline;
}
.Stage1 .Form .Item label {
  width: 25%;
  text-align: right;
}
.Stage1 .Form button {
  margin-top: 47px;
  width: 100%;
  background-color: #ed003d;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 0.5em;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  margin-right: 1em;
  transition: background-color 0.4s, color 0.4s, -webkit-transform 0.4s;
  transition: background-color 0.4s, color 0.4s, transform 0.4s;
  transition: background-color 0.4s, color 0.4s, transform 0.4s, -webkit-transform 0.4s;
  transition: background-color 0.4s, color 0.4s, transform 0.4s,
    -webkit-transform 0.4s;
  border: 1px solid transparent;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
}

.Stage1 .Form button.Disabled {
  background-color: gray;
}

.Stage2 {
  width: 40%;
  border-radius: 8px;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5em;
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.Stage2 .Form input,
.Stage2 .Form textarea {
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  width: 20%;
  font-size: 1.5em;
  outline: none;
  padding: 0.3em;
  color: #db0a41;
  font-weight: 100;
  margin: 0.5em;
  width: 75%;
}

.Stage2 .Form .Item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Stage2 .Form .Item.Top {
  align-items: baseline;
}
.Stage2 .Form .Item label {
  width: 25%;
  text-align: right;
}

.Stage2 .Buttons {
  display: flex;
  justify-content: center;
}
.Stage2 .Buttons button {
  background-color: #26ddac;
  color: #000;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 0.5em;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  margin-right: 1em;
  margin-left: 1em;
  transition: background-color 0.4s, color 0.4s, -webkit-transform 0.4s;
  transition: background-color 0.4s, color 0.4s, transform 0.4s;
  transition: background-color 0.4s, color 0.4s, transform 0.4s, -webkit-transform 0.4s;
  border: 1px solid transparent;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
}
.Stage2 .Buttons button.Secondary {
  background-color: transparent;
  border: 1px solid #ed003d;
}
.Stage2 .Buttons button.Secondary:hover {
  -webkit-transform: none;
          transform: none;
  background-color: #ed003d;
  color: #fff;
}
.Stage2 .Buttons button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.Page {
  background-color: rgb(11, 22, 47);
  padding: 5em;
  height: 100vh;
  box-sizing: border-box;
  border-top: 1px solid #202e4c;
}

.Page .PageNum {
  color: #f5d24b;
  font-size: 2em;
  margin-right: 1em;
}

.Page .PageDetails {
  display: flex;
  color: #fff;
  align-items: center;
}

.Page .Text {
  width: 50%;
  color: #020202;
  background: #ffffff;
  line-height: 1.8em;
  padding: 1em;
  border-radius: 4px;
  font-size: 1.5em;
}

.Page .GraphicPlayer {
  width: calc(1920px / 3);
  height: calc(1080px / 3);
  overflow: hidden;
  background-color: #252f44;
  border-radius: 3px;
}

.Page1 {
  position: relative;
}
.Page1 .Polygons {
  overflow: hidden;
  width: 100%;
  z-index: -1;
  height: 100%;
  position: absolute;
}

.Page1 .Polygons svg {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -3;
}

.Page1 .PolygonLeft {
  position: absolute;
  z-index: -2;
}

.Page1 .PolygonRight {
  position: absolute;
  z-index: -2;
}

.Page1 .HeroContent {
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 0;
}

.Page1 .HeroContent .Text {
  font-size: 5.03143rem;
  width: 55rem;
}

.Page1 .Background {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: -5;
}

.Page1 .Logos {
  display: flex;
  justify-content: center;
  width: 50%;
}
@media (max-width: 1152px) {
  .Page1 .PolygonInner {
    display: none;
  }
  .Page1 .HeroContent .Text {
    font-size: 2.03143rem;
    width: 20rem;
  }
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .Page1 .HeroContent {
    padding-left: 1rem;
    padding-bottom: 2.2rem;
  }

  .Page1 .HeroContent .Text {
    font-size: 1.73143rem;
    width: 20rem;
  }
}

.Page1 .Logo {
  height: 111px;
}
.Page1 .Scroll {
  position: absolute;
  bottom: 20px;
  color: #fff;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 2em;
  font-weight: 100;
}
.Page1 .Scroll svg {
  height: 30px;
  cursor: pointer;
}

.bounce {
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
}



@font-face {
  font-family: ELFontBold;
  src: url("/BasketballStyle/Font/CoText_Trial_Rg.ttf");
}

@font-face {
  font-family: ELFontRegular;
  src: url("/BasketballStyle/Font/CoText_Trial_Lt.ttf");
}
.LeagueTable {
  -webkit-transform: scale(0.346);
          transform: scale(0.346);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  height: 1080px;
  width: 1920px;
  background-image: url("/BasketballStyle/Images/Background.png");
}
.LeagueTable .Background {
  width: 1080px;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.LeagueTable .Background .OrangeContent {
  background-color: #f16228;
  width: 1064px;
  height: 100%;
  -webkit-clip-path: inset(0% 100% 0% 0%);
          clip-path: inset(0% 100% 0% 0%);
  transition: opacity 1s cubic-bezier(0.1, 0.2, 0, 1.01),
    -webkit-clip-path 1s cubic-bezier(0.1, 0.2, 0, 1.01);
  transition: clip-path 1s cubic-bezier(0.1, 0.2, 0, 1.01),
    opacity 1s cubic-bezier(0.1, 0.2, 0, 1.01);
  transition: clip-path 1s cubic-bezier(0.1, 0.2, 0, 1.01),
    opacity 1s cubic-bezier(0.1, 0.2, 0, 1.01),
    -webkit-clip-path 1s cubic-bezier(0.1, 0.2, 0, 1.01);
  position: absolute;
  display: flex;
  left: 410px;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.LeagueTable .Background .OrangeContent.Active {
  -webkit-clip-path: inset(0% 0% 0% 0%);
          clip-path: inset(0% 0% 0% 0%);
  opacity: 1;
}
.LeagueTable .Background .OrangeContent.Loop {
  -webkit-clip-path: inset(0% 99% 0% 0%);
          clip-path: inset(0% 99% 0% 0%);
}
.LeagueTable .Background .OrangeContent img {
  height: 40%;
}
.LeagueTable .Background .MainContent {
  display: flex;
  padding: 0;
  -webkit-clip-path: inset(0% 0% 0% 100%);
          clip-path: inset(0% 0% 0% 100%);
  transition: -webkit-clip-path 1s cubic-bezier(0.1, 0.2, 0, 1.01);
  transition: clip-path 1s cubic-bezier(0.1, 0.2, 0, 1.01);
  transition: clip-path 1s cubic-bezier(0.1, 0.2, 0, 1.01), -webkit-clip-path 1s cubic-bezier(0.1, 0.2, 0, 1.01);
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.9) 70%,
    100%,
    rgba(51, 51, 51, 0.9) 100%
  );
  height: 1080px;
}
.LeagueTable .Background .MainContent.Active {
  -webkit-clip-path: inset(0% 0% 0% 0%);
          clip-path: inset(0% 0% 0% 0%);
}
.LeagueTable .Background .LeftBar {
  width: 17px;
  height: 100%;
  background-color: #f16228;
  -webkit-clip-path: inset(100% 0% 100% 0%);
          clip-path: inset(100% 0% 100% 0%); /* Safari */
  transition: -webkit-clip-path 1s;
  transition: clip-path 1s;
  transition: clip-path 1s, -webkit-clip-path 1s;
}

.LeagueTable .Background .LeftColumn {
  width: 702px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.LeagueTable .Background .RightColumn {
  width: 362px;
  height: 100%;
  background-image: linear-gradient(
    rgba(51, 51, 51, 0.6) 70%,
    100%,
    rgba(102, 102, 102, 0.6) 100%
  );
  display: flex;
  flex-direction: column;
}

.LeagueTable .Background .LeftColumn .LeagueTableHeader h1 {
  color: #f16228;
  font-size: 73px;
  padding-left: 10px;
  margin-top: 44px;
  line-height: 73px;
  margin-bottom: 0px;
}
.LeagueTable .Background .LeftColumn .LeagueTableHeader h2 {
  color: #ffffff;
  font-size: 47px;
  padding-left: 10px;
  line-height: 47px;
  font-weight: 100;
  margin-top: 5px;
  margin-bottom: 0px;
}
.LeagueTable .Background .RightColumn .LeagueTableHeader img.ELLogo {
  margin-left: 15px;
  margin-top: 44px;
}

.LeagueTable .Background .RightColumn .LeagueTableHeader img.Sponsor {
  margin-top: 5px;
  margin-left: auto;
  float: right;
  margin-right: 22px;
}

.LeagueTable .Background .LeftColumn .SubHeader {
  color: #f16228;
  margin-left: 10px;
  font-size: 25px;
  margin-top: 28px;
}

.LeagueTable .Background .RightColumn .SubHeader {
  display: flex;
  color: #f16228;
  font-size: 25px;
  margin-top: 20px;
}
.LeagueTable .Background .RightColumn .SubHeader .Item {
  width: 25%;
  text-align: center;
}

.LeagueTable .Background .LeftColumn .Teams {
  display: flex;
  flex-direction: column;
  color: #fff;
}
.LeagueTable .Background .LeftColumn .Teams .Row {
  display: flex;
  align-items: center;
  font-size: 38px;
  height: 78px;
}

.LeagueTable .Background .LeftColumn .Teams .Row .Position {
  width: 80px;
  text-align: center;
}
.LeagueTable .Background .LeftColumn .Teams .Row .Badge {
  height: 78px;
}
.LeagueTable .Background .LeftColumn .Teams .Row .Team {
  line-height: 32px;
  font-size: 32px;
  margin-left: 10px;
  font-weight: 100;
}

.LeagueTable .Background .RightColumn .Teams {
  color: #fff;
  display: flex;
  flex-direction: column;
}

.LeagueTable .Background .RightColumn .Teams .Row {
  display: flex;
  align-items: center;
  height: 78px;
  font-size: 27px;
  text-align: center;
  font-family: "ELFontBold";
}
.LeagueTable .Background .RightColumn .Teams .Row div {
  width: 25%;
}

.FixedNav {
  position: fixed;
  right: 0px;
  top: 10%;
  background-color: #202e4c;
  width: 60px;
  border-radius: 4px;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.FixedNav .Item {
  padding: 0.5em;
  border-bottom: 1px solid #484848;
  cursor: pointer;
}
.FixedNav .Item.Active {
  color: #f5d24b;
}
.FixedNav .Item:last-child {
  border: none;
}

.PrivacyContent {
  padding: 0px 1.75rem;
  margin-bottom: 163px;
}

.PrivacyContent .Content {
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  -webkit-transform: translateZ(0px);
          transform: translateZ(0px);
  width: 100%;
  flex: 0 1 auto;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 4.2rem;
  box-sizing: border-box;
}

.PrivacyContent .Content h1 {
  text-align: center;
  margin-bottom: 2.1rem;
  font-size: 1.86607rem;
  line-height: 2.8rem;
  width: 100%;
}

.PrivacyBackgroundContainer {
}

.PrivacyBackground {
  position: absolute;
  background-color: #db0a41;
  height: calc(100% + 320px);
  width: 100%;
  padding-bottom: 100px;
  z-index: -5;
}

