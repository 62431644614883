.LowerSection {
  padding: 0px 1.75rem;
  margin-top: 3em;
}
.LowerSection.Social {
  margin-bottom: 3em;
  margin-top: 0px;
}
@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .LowerSection {
    padding: 0px 0.75rem;
  }
}

.LowerSection .Content {
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(25, 17, 34, 0.1) 0px 5px 20px;
  transform: translateZ(0px);
  width: 100%;
  flex: 0 1 auto;
  background: rgb(255, 255, 255);
  border-radius: 4px;
}

.AboutContent {
  text-align: center;
  padding: 0px 1.75rem;
}

.AboutContent p {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-weight: 100;
  margin-bottom: 59px;
  font-size: 1.4em;
}

@media (max-width: 650px), (max-width: 768px) and (orientation: portrait) {
  .AboutContent p {
    max-width: 100%;
  }
  .AboutContent {
    padding: 0px 0.75rem;
  }
}

.AboutContent p.HyperColour1 {
  color: #db0a41;
  /* font-weight: 400; */
  font-size: 1.6em;
}

.AboutUsPara {
  display: flex;
  flex-wrap: wrap;
}
.AboutUsPara .LeftAbout,
.AboutUsPara .RightAbout {
  /* width: 50%; */
  padding: 4em;
  text-align: left;
  font-size: 1.6em;
  margin-bottom: 0px;
  box-sizing: border-box;
}

.H {
  color: #db0a41;
  font-weight: 400;
}
.AboutUsPara .LeftAbout {
  font-size: 1.6em;
}

.AboutSplitter {
  width: 10%;
  height: 2px;
  border-radius: 4px;
  background-color: #db0a41;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1200px) {
  .AboutContent p {
    max-width: 100%;
  }
  .AboutContent p.HyperColour1 {
    font-size: 1.4em;
  }
  .AboutUsPara .LeftAbout,
  .AboutUsPara .RightAbout {
    padding: 2em;
    text-align: left;
    font-size: 1.2em;
    margin-bottom: 0px;
    width: 41%;
  }
}

@media (max-width: 750px) {
  .AboutUsPara .LeftAbout,
  .AboutUsPara .RightAbout {
    width: 100%;
  }
  .AboutContent p.HyperColour1 {
    font-size: 1.2em;
  }
  .AboutUsPara .LeftAbout,
  .AboutUsPara .RightAbout {
    font-size: 1.2em;
    padding: 1em;
  }
}
